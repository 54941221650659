<div >
  <img class="quantity-confirm-avatar-image" src="../../../assets/images/eyeblinkguy.svg" alt="avatar image">
  <h4 class="quantity-confirm-avatar-title" [ngStyle]="{color:theme?.title}">
    {{ 'DIALOG.QUANTITYCONFIRM.title' | translate }}
  </h4>
</div>
<div class="quantity-confirm-message">
  <h2 class="quantity-confirm-avatar-title" [ngStyle]="{color:theme?.title}">
    {{ 'DIALOG.QUANTITYCONFIRM.enterAmount' | translate }}
  </h2>
</div>
<div class="quantity-confirm-bottom">
  <button class="quantity-confirm-button-remove" (click)="removeProduct()">{{ 'CART.AMOUNT.remove' | translate }}</button>
  <input class="quantity-inbox-quantity box" #input inputmode="numeric"
         [ngStyle]="{background: theme?.background, color: theme?.foreground}" (blur)="onSubmit(input.value)"
         (keyup.enter)="onSubmit(input.value)"
         [ngModel]="value" >
  <button class="quantity-confirm-button-add" (click)="addProduct(input.value)">
    {{ 'DIALOG.QUANTITYCONFIRM.add' | translate }}
  </button>
</div>
