<div class="root">
  <div class="search-bar_container">
    <ng-container *ngTemplateOutlet="searchIcon">
    </ng-container>
    <input #input
           [ngModel]="inputText"
           (ngModelChange)="onInput($event)"
           (focus)="onFocus(true)"
           (blur)="onFocus(false)"
           matInput class="search-text"
           [placeholder]="hint">
    <ng-container *ngIf="!(inputText && inputText.length > 0) else clearIcon">
    </ng-container>
  </div>
  <div [ngStyle]="{background: theme?.title}"
       class="search-divider"
       [class.focused]="isFocused"></div>
</div>

<ng-template #searchIcon>
  <svg *ngIf="!(inputText && inputText.length > 0)"
       class="search-icon no-select" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11ZM16.8989
           19.0757C15.2452 20.2857 13.206 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11C21 13.5965
            20.0104 15.9619 18.3878 17.7395L21.3364 20.8726C21.715 21.2748 21.6958 21.9077 21.2936 22.2862C20.8914 22.6647 20.2585 22.6455
             19.88 22.2433L16.8989 19.0757Z"
          fill="#84889B"/>
  </svg>
</ng-template>

<ng-template #clearIcon>
  <mat-icon [ngStyle]="{color: theme?.title}"
            (click)="clear()"
            class="clear-icon no-select">
    clear
  </mat-icon>
</ng-template>
