
<ng-container *ngIf="stores != null">
  <h2>Velg butikk</h2>
  <ol class="chain-store-list_card drop-shadow">
    <li (click)="openStore(store)" *ngFor="let store of stores"
        class="chain-store-list_item">
      <div class="chain-store-list_item-image">
        <img src="{{getStoreImage(store.logoImage, 80)}}">
      </div>
      <div class="chain-store-list_item-description">
        <h3>{{store.title}}</h3>
        <h4>{{store.address}}, {{store.city}}</h4>
        <h5 *ngIf="store.distance">{{store.distance.toFixed(1)}} km</h5>
      </div>
    </li>
  </ol>
  <button (click)="onLocateClicked()" class="chain-store-list_scan-button">
    Finn nærmeste
  </button>
</ng-container>

<ng-container *ngIf="stores == null">
  <li class="chain-store-list_item loading">
    <div class="chain-store-list_item-image" style="background: #f4f4f4"></div>
    <div class="chain-store-list_item-description">
      <app-gradient-loader></app-gradient-loader>
      <app-gradient-loader style="width: 70%"></app-gradient-loader>
    </div>
  </li>
</ng-container>
