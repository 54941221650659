import {Component, OnInit} from '@angular/core';
import {Store} from '../../../domain/models/store/store';
import {Cart} from '../../../domain/models/order/cart';
import {Theme} from '../../../domain/models/store/theme';
import {ActivatedRoute, Router} from '@angular/router';
import {StoreService} from '../../../domain/store.service';
import {ForegroundPaths} from '../../../app-routing.module';

@Component({
  selector: 'app-payment-failed',
  templateUrl: './payment-failed.component.html',
  styleUrls: ['./payment-failed.component.sass'],
})
export class PaymentFailedComponent implements OnInit {

  store?: Store;
  cart?: Cart;
  theme?: Theme;

  constructor(private router: Router,
              private storeService: StoreService,
              private route: ActivatedRoute) {
  }

  async ngOnInit(): Promise<void> {
    const storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
    this.store = await this.storeService.getStore(storeHandle);
    this.theme = this.store.theme;
  }

  async closeForeground() {
    await this.router.navigate(ForegroundPaths.cart());
  }

}
