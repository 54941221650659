import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ShippingAddress} from '../../domain/models/order/shipping-address';
import {StoreService} from '../../domain/store.service';
import {Theme} from '../../domain/models/store/theme';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.sass'],
})
export class AddressFormComponent implements OnInit {
  addressForm: FormGroup;
  theme?: Theme;

  @Output() onAddressSubmitted = new EventEmitter<ShippingAddress>();
  @Output() onClose = new EventEmitter<void>();
  @Input() fulfilmentAddress: ShippingAddress | undefined;

  constructor(private storeService: StoreService) {
    this.addressForm = new FormGroup({
      emailAddress: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
      ]),
      fullName: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
      ]),
      addressLine1: new FormControl(''),
      addressLine2: new FormControl(''),
      city: new FormControl(''),
      postalCode: new FormControl(''),
      countryName: new FormControl(''),
    });
  }

  async ngOnInit() {
    if (this.storeService.storeId) {
      this.theme = (await this.storeService.getStore(this.storeService.storeId))?.theme;
    }

    if (this.fulfilmentAddress) {
      this.addressForm.setValue({
        emailAddress: this.fulfilmentAddress.emailAddress != '-' ? this.fulfilmentAddress.emailAddress : '',
        fullName: this.fulfilmentAddress.fullName != '-' ? this.fulfilmentAddress.fullName : '',
        addressLine1: this.fulfilmentAddress.addressLine1 != '-' ? this.fulfilmentAddress.addressLine1 : '',
        addressLine2: this.fulfilmentAddress.addressLine2 != '-' ? this.fulfilmentAddress.addressLine2 : '',
        city: this.fulfilmentAddress.city != '-' ? this.fulfilmentAddress.city : '',
        postalCode: this.fulfilmentAddress.postalCode != '-' ? this.fulfilmentAddress.postalCode : '',
        countryName: this.fulfilmentAddress.countryName != '-' ? this.fulfilmentAddress.countryName : '',
      });
    }
  }

  onFormSubmit() {
    if (this.addressForm.invalid) {
      return;
    }

    const addressForm = this.addressForm.value;

    this.onAddressSubmitted.emit(new class implements ShippingAddress {
      emailAddress = addressForm.emailAddress.toLowerCase();
      fullName = addressForm.fullName;
      addressLine1 = addressForm.addressLine1;
      addressLine2 = addressForm.addressLine2;
      city = addressForm.city;
      postalCode = addressForm.postalCode;
      countryName = addressForm.countryName;
    });
  }
}
