import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageService} from 'ngx-webstorage';
import {CustomerService} from './domain/customer.service';
import {LoginDialogState} from './dialogs/login-dialog/login.dialog';
import {decodeJwt} from 'jose';
import {environment} from '../environments/environment';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  private isRefreshingToken = false;
  private readonly baseUrl: string;

  constructor(private toastr: ToastrService,
              private storageService: LocalStorageService,
              private customerService: CustomerService,
  ) {
    this.baseUrl = new URL(environment.apiUrl).origin;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // TODO This should be replaced with a route middleware at some point
    if (req.url.includes(this.baseUrl) && !this.isRefreshingToken) {
      const jwt = this.customerService.getAndValidateToken();

      if (jwt === false) {
        const {CustomerId, Anonymous} = decodeJwt(this.customerService.retrieveToken());

        if (Anonymous == 'True') {
          req = this.refreshToken(CustomerId as string, req, next);
        } else if (Anonymous == 'False') {
          this.customerService.openLoginDialog(LoginDialogState.LoginExpired);

          return next.handle(req);
        }
      } else if (jwt !== null) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${jwt}`,
          },
        });
      } else {
        // Backwards compatibility for customers without JWTs
        const customerId = this.storageService.retrieve('customer-id') as string;
        if (customerId) {
          req = this.refreshToken(customerId, req, next);
        }
      }

      return next.handle(req)
        .pipe(
          // Handle errors
          catchError((error: HttpErrorResponse) => {
            const statusCode = error.error?.status ?? error.status ?? 0;

            if ((statusCode == 401 || statusCode == 403) && this.customerService.isAnonymousUser() !== true) {
              this.customerService.openLoginDialog(LoginDialogState.LoginRequired);
            } else if (statusCode != 404 && statusCode != 0) {
              this.toastr.warning(`Something went wrong (${statusCode})`, 'Error', {
                timeOut: 8000,
                easeTime: 100,
                positionClass: 'toast-bottom-center',
              });
            }

            return throwError(error);
          }),
        );
    }

    return next.handle(req);
  }

  private refreshToken(customerId: string, req: HttpRequest<any>, next: HttpHandler) {
    this.isRefreshingToken = true;
    this.customerService.customerAnonymousAuthenticate(customerId)
      .then(() => {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${this.customerService.retrieveToken()}`,
          },
        });
        this.isRefreshingToken = false;

        return next.handle(req);
      })
      .finally(() => this.isRefreshingToken = false);

    return req;
  }
}
