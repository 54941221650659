import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Theme} from '../../domain/models/store/theme';

@Component({
  templateUrl: './confirm-delivery.dialog.html',
  styleUrls: ['./confirm-delivery.dialog.sass'],
})
export class ConfirmDeliveryDialog {
  theme?: Theme;

  constructor(public ref: MatDialogRef<ConfirmDeliveryDialog>,
              @Inject(MAT_DIALOG_DATA) data: { theme?: Theme },
  ) {
    this.theme = data.theme;
  }

  submit(value: boolean) {
    this.ref.close(value);
  }
}
