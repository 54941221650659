<div class="root" [ngStyle]="!isChrome ? {'min-height': '100%'} : {}">
  <app-toolbar title="{{ 'ORDERS.purchase' | translate }} {{ order ? '#'+order.number : '' }}"
               (onBackClicked)="onBackClicked()"
               [subtitle]="store?.title"
               [hasMenu]="false">
  </app-toolbar>

  <div class="order-details">
    <div class="order-details-scan-vending" *ngIf="order?.vendingCode">
      <div class="order-details-scan-title">{{ 'RECEIPT.thanksVending' | translate }}</div>
      <ngx-barcode6
        [bc-format]="'CODE128'"
        [bc-value]="order!.vendingCode!"
        [bc-width]="order!.vendingCode!.length > 10? 1.6 : 2"
        [bc-height]="60"
        [bc-margin-left]="1"
        [bc-margin-right]="1"
        [bc-margin-bottom]="30"
        [bc-display-value]="false">
      </ngx-barcode6>
    </div>

    <app-receipt [order]="order" [store]="store" [showOuterLines]="false"></app-receipt>
  </div>
  <div *ngIf="showControl" class="order-details-control-wrapper">
    <button class="order-details-control-button" (click)="goToReceiptPage()">
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
        <mask id="mask0_2400_4485" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
          <rect x="0.5" width="24" height="24" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_2400_4485)">
          <path
            d="M6.87305 11.7499L10.1961 15.073C10.3449 15.2217 10.4183 15.3957 10.4163 15.5951C10.4144 15.7945 10.3359 15.9717 10.1808 16.1268C10.0256 16.2717 9.85 16.3467 9.65385 16.3518C9.4577 16.3569 9.28206 16.2819 9.12695 16.1268L4.63272 11.6326C4.45196 11.4518 4.36157 11.2409 4.36157 10.9999C4.36157 10.7589 4.45196 10.548 4.63272 10.3672L9.12695 5.87301C9.2654 5.73454 9.43686 5.66371 9.64135 5.66051C9.84583 5.65729 10.0256 5.72812 10.1808 5.87301C10.3359 6.02812 10.4134 6.20632 10.4134 6.4076C10.4134 6.60887 10.3359 6.78707 10.1808 6.94221L6.87305 10.2499H16.25C17.5628 10.2499 18.6827 10.7134 19.6096 11.6403C20.5365 12.5672 21 13.6871 21 14.9999V17.7499C21 17.9627 20.9282 18.1409 20.7846 18.2845C20.641 18.4281 20.4628 18.4999 20.25 18.4999C20.0372 18.4999 19.859 18.4281 19.7154 18.2845C19.5718 18.1409 19.5 17.9627 19.5 17.7499V14.9999C19.5 14.1025 19.1827 13.3364 18.5481 12.7018C17.9135 12.0672 17.1474 11.7499 16.25 11.7499H6.87305Z"
            fill="white"/>
        </g>
      </svg>
      {{ 'ORDERS.backToControl' | translate }}
    </button>
  </div>
  <div class="order-details-receipt-button">
    <app-receipt-email-button
      *ngIf="order"
      [orderId]="order.id"
      [useGiftReceipt]="false"
      [buttonStyle]="showControl ? 'link' : 'button'">
    </app-receipt-email-button>
  </div>
</div>
