import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Product} from '../../domain/models/product/product';
import {ImageUtils} from '../../utils/image.utils';
import {PriceUtils} from '../../utils/price.utils';
import {Theme} from '../../domain/models/store/theme';
import {Campaign} from '../../domain/models/campaign/campaign';
import {CampaignUtils} from '../../utils/campaign.utils';
import {CampaignRuleScope} from '../../domain/models/campaign/rule';
import {Store} from '../../domain/models/store/store';
import {CartService} from '../../domain/cart.service';
import {Router} from '@angular/router';
import {DimensionAvailability} from '../../domain/models/product/dimension-availability';
import {DimensionAvailabilityService} from '../../domain/dimension-availability.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService} from 'ngx-webstorage';
import {AdjustmentTypeService} from '../../domain/adjustment-type.service';
import {ProductType} from '../../domain/models/product/product-microshop';
import {ForegroundPaths} from '../../app-routing.module';
import {ProductService} from '../../domain/product.service';
import {delay} from '../../utils/promise.utils';
import {bugsnagStartSpan} from '../../bugsnag';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.sass'],
})
export class ProductCardComponent implements OnInit {

  @Input() set product(value: ProductType | undefined) {
    this.setProduct(value);
  }
  get product(): ProductType | undefined {
    return this._product;
  }
  @Input() set store(value: Store | undefined) {
    this.setStore(value);
  }
  get store(): Store | undefined {
    return this._store;
  }

  @Output() onClick = new EventEmitter();
  @Input() hasShadow = false;
  @Input() currencyCode: string | undefined;
  @Input() cultureName: string | undefined;

  isBusy = false;
  campaigns?: Campaign[];
  theme?: Theme;
  price?: string;

  dimensionAvailability: DimensionAvailability[] = [];
  availabilityCount: number | undefined;
  isStock = false;

  private _store?: Store;
  private _product?: ProductType;

  constructor(private cartService: CartService,
              private router: Router,
              private toastr: ToastrService,
              private translateService: TranslateService,
              private storageService: LocalStorageService,
              private adjustmentService: AdjustmentTypeService,
              private dimensionAvailabilityService: DimensionAvailabilityService,
              private productService: ProductService,
  ) {
  }


  async ngOnInit() {
    this.theme = this.store?.theme;
    if (this.store != null && this.product != null) {
      this.campaigns = CampaignUtils.campaignsForProduct(this.store, this.product?.id);
      this.setPrice();
    }
  }

  private setPrice() {
    if (this.product != null) {
      let storedAdjustmentTypeId = this.adjustmentService.getStoredTypeId(this.store!.handle);
      if (storedAdjustmentTypeId != null) {
        const price = this.product!.dimensions[0].prices.find(p => p.adjustmentType.id == storedAdjustmentTypeId);
        this.price = PriceUtils.toFriendlyPrice(price!.amount, this.product?.isWeight ?? false);
      } else {
        this.price = PriceUtils.toFriendlyPrice(this.product!.dimensions[0].prices[0].amount, this.product?.isWeight ?? false);
      }
    }
  }

  getProductImage(product: ProductType, size: number): string | undefined {
    return ImageUtils.getImageUrl(product.images[0], size, false);
  }

  getFallBackImage(): string {
    if (this.store?.logoImage) {
      return this.store.logoImage.url;
    }

    return '/assets/images/noimage.png';
  }

  toFriendlyPrice(price: number): string {
    return PriceUtils.toFriendlyPrice(price, this.product?.isWeight ?? false);
  }

  getCheapestPrice(product: ProductType, campaigns: Campaign[]): number {
    return CampaignUtils.getCheapestPrice(product, campaigns);
  }

  hasProductCampaign(campaigns: Campaign[]): boolean {
    return campaigns.filter(c => c.rule.scope === CampaignRuleScope.Product).length > 0;
  }

  isQuickAddAvailable(): boolean {
    return this.product != null && this.store != null;
  }

  async quickAdd(storeHandle: string, $event: Event) {
    $event.stopPropagation();
    const span = bugsnagStartSpan('product-card:quickAdd');
    this.isBusy = true;

    if (!('description' in this._product!)) {
      // Fetch entire product if type is ProductMicroshop
      const product = await this.productService.getProductById(storeHandle, this._product!.id);
      this.setProduct(product);
    }

    const isShipmentDefault = (this.product as Product).fulfillmentOptions?.find(option => option.type === 'shipment')?.default;
    if (isShipmentDefault != undefined && !isShipmentDefault && this.product && this.store) {
      await this.router.navigate(ForegroundPaths.empty());
      await delay(80);
      await this.cartService.add(this.store.handle, this.product as Product);
      this.isBusy = false;
      span?.end();
      return;
    }

    const itemCount = await this.cartService.getItemCount(storeHandle, this._product! as Product, this._product!.dimensions[0]);
    this.dimensionAvailability = await this.dimensionAvailabilityService.getStock(storeHandle, this._product?.id!);
    if (this.dimensionAvailability[0].trackStock) {
      this.isStock = true;
    }
    this.availabilityCount = this.dimensionAvailability[0].count;
     if (this.store != null && this.dimensionAvailability[0].trackStock && this.dimensionAvailability[0].count > itemCount || this.store != null && !this.dimensionAvailability[0].trackStock) {
      await this.router.navigate(ForegroundPaths.empty());
      await delay(80);
      await this.cartService.add(this.store!.handle, this.product! as Product);
    } else {
      const message = await this.translateService.get('PRODUCTDETAILS.noStock').toPromise();
      this.toastr.error(message, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
    }
    this.isBusy = false;
    span?.end();
  }

  private setStore(value: Store | undefined) {
    this._store = value;
    this.theme = value?.theme;
    if (value != null && this.product != null) {
      this.campaigns = CampaignUtils.campaignsForProduct(value, this.product?.id);
      this.setPrice();
    }
  }

  private setProduct(value: ProductType | undefined) {
    this._product = value;
    if (this.store != null && value != null) {
      this.campaigns = CampaignUtils.campaignsForProduct(this.store, value.id);
      this.setPrice();
    }
  }
}
