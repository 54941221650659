<div class="weight-product-search-list">
  <app-search-bar
    (doSearch)="doSearch(storeHandle, $event)"
    (doClear)="doClear()"
    (focusChanged)="onFocusChanged($event)"
    [theme]="theme"
    hint="{{ 'SEARCH.PRODUCT.hint' | translate }}">
  </app-search-bar>
  <div class="weight-product-search_spacer" *ngIf="!isSearchBarFocused">
  </div>
  <div class="weight-product-search_search-container"
       *ngIf="isSearchBarFocused || (searchQuery && searchQuery.length >= 3)"
       (scroll)="onSearchScrolled($event)">
    <ng-container *ngIf="searchHandler.isBusy && searchHandler.data.length === 0">
      <ng-container *ngFor="let _ of [0, 0, 0]">
        <app-product-item></app-product-item>
      </ng-container>
    </ng-container>
    <ng-container *ngFor="let product of searchHandler.data">
      <div class="weight-product-search_product-container">
        <app-product-item
          [product]="product">
        </app-product-item>
        <button class="weight-product-search_search-container_button"
                (click)="selectProduct(product)"
                [ngStyle]="{color: theme?.title}">
          {{ 'WEIGHT.chooseProduct' | translate }}
        </button>
        <div class="weight-product-search_spacer">
        </div>
      </div>
    </ng-container>
    <ng-container
      *ngIf="!searchHandler.isBusy && searchHandler.data.length === 0 && searchQuery && searchQuery.length >= 3">
      <p class="weight-product-search_no_result-text" [ngStyle]="{color: theme?.title}">
        {{ 'WEIGHT.resultsEmpty' | translate }}
      </p>
    </ng-container>
  </div>
</div>
<div class="weight-product-search_weight">
  <h4 class="weight-product-search_title" [ngStyle]="{color: theme?.title}">{{ 'WEIGHT.weight' | translate }}</h4>
  <h5 class="weight-product-search_amount" [ngStyle]="{color: theme?.title}">{{weight}}</h5>
</div>
