import { Component, OnInit } from '@angular/core';
import {Store} from "../../../domain/models/store/store";
import {StoreService} from "../../../domain/store.service";
import {ActivatedRoute, Event, NavigationEnd, Router} from "@angular/router";
import {ProductService} from "../../../domain/product.service";
import {Product} from "../../../domain/models/product/product";
import {Paginated} from "../../../transport/models/paginated";
import {Theme} from "../../../domain/models/store/theme";
import {ForegroundPaths} from "../../../app-routing.module";
import {CategoryShallow} from "../../../domain/models/category/category";
import {ScannerService} from "../../../domain/scanner.service";

@Component({
  selector: 'app-behind-counter-menu',
  templateUrl: './behind-counter-menu.component.html',
  styleUrls: ['./behind-counter-menu.component.sass']
})
export class BehindCounterMenuComponent implements OnInit {
  store?: Store;
  theme?: Theme;
  products?: Paginated<Product>
  currentStore: Store | undefined;
  currentCategory: CategoryShallow | undefined;

  constructor( private storeService: StoreService,
               private productService: ProductService,
               private router: Router,
               private scannerService: ScannerService
               )
  { }

  async ngOnInit(): Promise<void> {
    this.store = await this.storeService.getStore(this.storeService.storeId!);
    this.theme = this.store.theme;
    this.products = await this.productService.getProductsBehindCounter(this.storeService.storeId!);
  }

  async openProduct(productHandle: string) {
    await this.router.navigate(ForegroundPaths.product(productHandle));
  }

}
