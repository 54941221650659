import {Component, Input, OnInit} from '@angular/core';
import {Image} from '../../domain/models/product/image';
import {ImageUtils} from '../../utils/image.utils';
import {ForegroundPaths} from '../../app-routing.module';
import {Product} from '../../domain/models/product/product';
import {ActivatedRoute, Router} from '@angular/router';
import {Dimension} from '../../domain/models/product/dimension';
import {OrderLine} from "../../domain/models/order/cart";
import {StoreService} from "../../domain/store.service";
import {Store} from "../../domain/models/store/store";

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.sass']
})
export class ProductItemComponent implements OnInit {

  @Input() dropShadow: boolean = false;
  @Input() product?: Product;
  @Input() dimension?: Dimension;
  @Input() backgroundColor: string = 'white';
  @Input() orderLine?: OrderLine;
  store: Store | undefined;
  currencyCode: string | undefined;
  cultureName: string | undefined;

  constructor(private router: Router,
              private storeService: StoreService,
              private route: ActivatedRoute
  ) {
  }

  async ngOnInit(): Promise<void> {
    const storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
    this.store = await this.storeService.getStore(storeHandle);
    this.currencyCode = this.store.currencyCode;
    this.cultureName = this.store.cultureName;
  }

  getProductImage(images: Image[] | undefined, size: number): string | undefined {
    if (images && images.length > 0) {
      return ImageUtils.getImageUrl(images[0], size);
    }
    return undefined;
  }

  getFallBackImage(): string {
    if (this.store?.logoImage){
      return this.store.logoImage.url;
    }

    return '/assets/images/noimage.png';
  }

  async openProductDetails() {
    if (this.product != null) {
      await this.router.navigate(ForegroundPaths.product(this.product.handle));
    }
  }
}
