<div class="product-card_container" [ngClass]="{'shadow': hasShadow}">
  <div class="product-card_image"
       (click)="this.onClick.emit()">
    <app-gradient-loader style="object-fit: contain"></app-gradient-loader>

    <ng-container *ngIf="product">
      <app-image-fading *ngIf="product.images && product.images.length >= 1" source="{{getProductImage(product, 599)}}">
      </app-image-fading>
    </ng-container>

    <ng-container *ngIf="product">
      <app-image-fading *ngIf="!product.images || product.images.length == 0" source="{{getFallBackImage()}}"
                        style="object-fit: contain; width: 100%; height: 90%">
      </app-image-fading>
    </ng-container>

    <div class="product-quick-purchase" *ngIf="isQuickAddAvailable()">
      <div (throttleClick)="quickAdd(store?.handle!, $event)"
           [ngStyle]="{background: theme?.payForeground}"
           [ngClass]="{'isBusy': isBusy}"
           class="product-quick-purchase-button">
        <mat-icon [ngStyle]="{color: theme?.payBackground}">
          add_shopping_cart
        </mat-icon>
      </div>
    </div>
  </div>
  <div class="product-card_details"
       (click)="this.onClick.emit()">
    <div *ngIf="product != null, else loadingBlock">
      <div class="product-card_title" [ngStyle]="{color: theme?.title}">
        {{product.name}}
      </div>
      <div class="product-card_price" [ngStyle]="{color: theme?.price}">
        <span class="product-card-campaign-price" *ngIf="campaigns != null && hasProductCampaign(campaigns)">
          {{toFriendlyPrice(getCheapestPrice(product, campaigns)) | currency:currencyCode:'symbol-narrow': '':cultureName}}
        </span>
        <span *ngIf="campaigns != null && hasProductCampaign(campaigns); else originalPrice"
              [ngClass]="{'original-price': campaigns != null && hasProductCampaign(campaigns)}">
          {{ this.price }}
        </span>
        <ng-template #originalPrice>
          <span>{{ this.price | currency:currencyCode:'symbol-narrow': '':cultureName}}
          </span>
        </ng-template>
      </div>
    </div>
    <ng-template #loadingBlock>
      <app-gradient-loader></app-gradient-loader>
      <app-gradient-loader style="width: 80%; margin-top: 8px"></app-gradient-loader>
    </ng-template>
  </div>
</div>
