<form [formGroup]="addressForm" novalidate (ngSubmit)="onFormSubmit()">
  <div class="address-form">
    <h3 class="address-form__title">{{ 'ADDRESSFORM.title' | translate }}</h3>

    <mat-label class="address-form__label">{{ 'ADDRESSFORM.e-post' | translate }}</mat-label>
    <mat-form-field appearance="fill">
      <input [type]="'email'"
             matInput
             formControlName="emailAddress"
             placeholder="{{ 'ADDRESSFORM.email' | translate }}"
             required
             autocomplete="email"
             inputmode="email">
      <mat-error *ngIf="addressForm.get('emailAddress')?.hasError" class="address-form__error">
        {{ 'ADDRESSFORM.emailError' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-label class="address-form__label">{{ 'ADDRESSFORM.full-name' | translate }}</mat-label>
    <mat-form-field appearance="fill">
      <input matInput formControlName="fullName" placeholder="{{ 'ADDRESSFORM.fullName' | translate }}" required autocomplete="name">
      <mat-error *ngIf="addressForm.get('fullName')?.hasError" class="address-form__error">
        {{ 'ADDRESSFORM.nameError' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-label class="address-form__label">{{ 'ADDRESSFORM.address' | translate }}</mat-label>
    <mat-form-field appearance="fill">
      <input matInput id="addressLine1" formControlName="addressLine1" placeholder="{{ 'ADDRESSFORM.addressLine1' | translate }}">
    </mat-form-field>

    <mat-label class="address-form__label">{{ 'ADDRESSFORM.house' | translate }}</mat-label>
    <mat-form-field appearance="fill">
      <input matInput formControlName="addressLine2" placeholder="{{ 'ADDRESSFORM.addressLine2' | translate }}">
    </mat-form-field>

    <mat-label class="address-form__label">{{ 'ADDRESSFORM.postalCode' | translate }}</mat-label>
    <mat-form-field appearance="fill">
      <input matInput formControlName="postalCode" placeholder="{{ 'ADDRESSFORM.enterPostalCode' | translate }}" autocomplete="postal-code">
    </mat-form-field>

    <mat-label class="address-form__label">{{ 'ADDRESSFORM.city' | translate }}</mat-label>
    <mat-form-field appearance="fill">
      <input matInput formControlName="city" placeholder="{{ 'ADDRESSFORM.enterCity' | translate }}" autocomplete="city">
    </mat-form-field>

    <mat-label class="address-form__label">{{ 'ADDRESSFORM.country' | translate }}</mat-label>
    <mat-form-field appearance="fill">
      <input matInput formControlName="countryName" placeholder="{{ 'ADDRESSFORM.enterCountry' | translate }}" autocomplete="country">
    </mat-form-field>
  </div>

  <div class="button-group">
    <button class="address-submit-button"
            [ngStyle]="{background: theme?.payForeground, color: theme?.payBackground}"
            [disabled]="this.addressForm.invalid">
      {{ 'ADDRESSFORM.button' | translate }}
    </button>
  </div>
</form>
