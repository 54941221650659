import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CartService} from '../../../domain/cart.service';
import {OrderLineType, ProductOrderLine} from '../../../domain/models/order/order-line';
import {ForegroundPaths} from '../../../app-routing.module';
import {StoreService} from '../../../domain/store.service';
import {Theme} from '../../../domain/models/store/theme';

@Component({
  selector: 'app-weight',
  templateUrl: './weight.component.html',
  styleUrls: ['./weight.component.sass']
})
export class WeightComponent implements OnInit {
  weight: number;
  storeHandle: string;
  availableOrderLines?: ProductOrderLine[];
  theme?: Theme;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private cartService: CartService,
              private storeService: StoreService) {
    this.storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
    this.weight = Number(this.route.snapshot.paramMap.get('weightInGrams')!);
  }

  async ngOnInit() {
    this.storeService.getStore(this.storeHandle).then(store => this.theme = store.theme);
    let cart = await this.cartService.getOrCreateLocalCart(this.storeHandle);
    this.availableOrderLines = cart.orderLines
      .filter(line => line.type === OrderLineType.Product)
      .map(line => line as ProductOrderLine)
      .filter(line => line.product.isWeight)
      .filter(line => line.quantity === 0);
    if (this.availableOrderLines.length === 0) {
      await this.router.navigate(ForegroundPaths.weightProductSearch(this.weight));
    }
  }

  async onSelected(orderLine: ProductOrderLine) {
    await this.cartService.setWeight(orderLine.id, this.weight, this.storeHandle);
    await this.router.navigate(ForegroundPaths.empty());
  }

  async searchProductRoute() {
    await this.router.navigate(ForegroundPaths.weightProductSearch(this.weight));
  }
}
