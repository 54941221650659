export class DistanceUtils {

  static calculateDistance(lat1: number, long1: number, lat2: number, long2: number) {
    const p = 0.017453292519943295;    // Math.PI / 180
    const c = Math.cos;
    const a = 0.5 - c((lat1 - lat2) * p) / 2 + c(lat2 * p) * c((lat1) * p) * (1 - c(((long1 - long2) * p))) / 2;
    return (12742 * Math.asin(Math.sqrt(a)));
  }
}

export type Distanced<T> = Partial<T> & { distance?: number }
