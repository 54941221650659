import {Component, Input, OnInit} from '@angular/core';
import {OrderLine} from '../../domain/models/order/cart';
import {OrderLineType} from '../../domain/models/order/order-line';
import {ProductService} from '../../domain/product.service';
import {StoreService} from '../../domain/store.service';
import {Product} from '../../domain/models/product/product';
import {Image} from '../../domain/models/product/image';
import {ImageUtils} from '../../utils/image.utils';
import {Campaign} from '../../domain/models/campaign/campaign';
import {ActivatedRoute} from '@angular/router';
import {Store} from '../../domain/models/store/store';

@Component({
  selector: 'app-receipt-product-image',
  templateUrl: './receipt-product-image.component.html',
  styleUrls: ['./receipt-product-image.component.sass'],
})

export class ReceiptProductImageComponent implements OnInit {
  OrderLineType = OrderLineType;
  @Input() orderLine?: OrderLine;
  product!: Product;
  campaign?: Campaign;
  store?: Store;

  constructor(private productService: ProductService,
              private storeService: StoreService,
              private route: ActivatedRoute,
  ) {
  }

  async ngOnInit() {
    const storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
    this.storeService.storeId = storeHandle;
    this.store = await this.storeService.getStore(storeHandle);
    if (this.orderLine != null) {
      switch (this.orderLine.type) {
        case OrderLineType.Product:
          const selected = this.orderLine.product;
          this.product = await this.productService.getProductById(storeHandle, selected.id);
          break;
        case OrderLineType.Campaign:
          this.campaign = this.orderLine.campaign;
          break;
      }
    }
  }

  getProductImage(images: Image[] | undefined, size: number): string | undefined {
    if (images && images.length > 0) {
      return ImageUtils.getImageUrl(images[0], size);
    }
    return undefined;
  }

  getFallBackImage(): string {
    if (this.store?.logoImage){
      return this.store.logoImage.url;
    }

    return '/assets/images/noimage.png';
  }


  getCampaignImage(image: Image | undefined, size: number): string {
    if (image != null) {
      return ImageUtils.getImageUrl(image, size) ?? ImageUtils.fallbackImage;
    }
    return ImageUtils.fallbackImage;
  }
}
