import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Theme} from '../../domain/models/store/theme';
import {ActivatedRoute, Router} from '@angular/router';
import {OfflineProductOrderLine} from '../../domain/models/order/offline-product-order-line';
import {ProductSummary} from '../../domain/models/product/product-summary';
import {ProductDimensionSummary} from '../../domain/models/product/product-dimension-summary';

@Component({
  selector: 'app-offline-cart-item',
  templateUrl: './offline-cart-item.component.html',
  styleUrls: ['./offline-cart-item.component.sass']
})
export class OfflineCartItemComponent {
  @Input() orderLine?: OfflineProductOrderLine;
  @Input() theme?: Theme;
  @Input() isBusy: boolean = false;
  @Input() dropShadow: boolean = false;

  @Output() quantityClicked = new EventEmitter<OfflineProductOrderLine>();
  @Output() removeClicked = new EventEmitter<OfflineProductOrderLine>();
  product?: ProductSummary;
  dimension?: ProductDimensionSummary;

  constructor(private route: ActivatedRoute,
              private router: Router) {
  }

  onQuantityClicked(orderLine: OfflineProductOrderLine) {
    this.quantityClicked.emit(orderLine);
  }

  remove() {
    this.removeClicked.emit(this.orderLine!);
  }
}
