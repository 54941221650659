<div class="weight-select-item_root">
  <app-product-item
    [product]="product"
    [backgroundColor]="'transparent'">

  </app-product-item>
  <button class="weight-select-item_root button" (click)="onSelected.emit(orderLine)"
          [ngStyle]="{color: theme?.actionForeground, background: theme?.actionBackground}">
    {{ 'WEIGHT.chooseProduct' | translate }}
  </button>
</div>
