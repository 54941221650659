import {Component, OnInit} from '@angular/core';
import {CartService} from '../../../domain/cart.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StoreService} from '../../../domain/store.service';
import {Store} from '../../../domain/models/store/store';
import {AdjustmentType} from '../../../domain/models/product/adjustmentType';
import {ForegroundPaths} from '../../../app-routing.module';
import {LocalStorageService} from 'ngx-webstorage';
import {Theme} from '../../../domain/models/store/theme';

@Component({
  selector: 'app-adjustment-type-chooser',
  templateUrl: './adjustment-type-chooser.component.html',
  styleUrls: ['./adjustment-type-chooser.component.sass']
})
export class AdjustmentTypeChooserComponent implements OnInit {
  store: Store | undefined;
  adjustmentTypes: AdjustmentType[] | undefined;
  productHandle: string | undefined;
  theme?: Theme;

  constructor(private route: ActivatedRoute,
              private storageService: LocalStorageService,
              private router: Router,
              private cartService: CartService,
              private storeService: StoreService) {
  }

  async ngOnInit() {
    const storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
    this.productHandle = this.route.snapshot.paramMap.get('productHandle')!;
    this.store = await this.storeService.getStore(storeHandle);
    this.theme = this.store.theme;
    this.adjustmentTypes = this.store.adjustmentTypes;
  }

  getTypeName(name: string): string {
    if (name === 'TakeAway') {
      return 'Ta med';
    } else if (name === 'EatIn') {
      return 'Spis her';
    }

    return 'Unknown';
  }

  async typeChosen(adjustment: AdjustmentType) {
    if (this.productHandle != null) {
      this.storageService.store('pendingAddCartProduct', this.productHandle);
    }

    await this.cartService.setAdjustmentType(this.store!.handle, adjustment);
    await this.router.navigate(ForegroundPaths.empty()).then(val => {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
      });
    });
  }
}
