<h3 [ngStyle]="{color: currentStore!.theme?.title}" (click)="getCategoryDetails()" class="category-list-title">
  {{currentCategory!.title}}
</h3>
<div class="product-list" *ngIf="categoryDetails != undefined && categoryDetails!.products.length > 0">
  <div class="product-background" *ngFor="let product of categoryDetails!.products">
    <div class="products">
      <app-product-card
        [product]="product"
        [store]="currentStore"
        (onClick)="openProduct(product.handle)"
        [cultureName]="currentStore!.cultureName"
        [currencyCode]="currentStore!.currencyCode">
      </app-product-card>
    </div>
  </div>
</div>
