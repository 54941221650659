import {Component, OnInit} from '@angular/core';
import {ProductService} from '../../../domain/product.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PaginationHandler} from '../../../domain/pagination/pagination.handler';
import {Product} from '../../../domain/models/product/product';
import {CartService} from '../../../domain/cart.service';
import {ForegroundPaths} from '../../../app-routing.module';
import {Theme} from '../../../domain/models/store/theme';
import {StoreService} from '../../../domain/store.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-weight-product-search-list',
  templateUrl: './weight-product-search-list.component.html',
  styleUrls: ['./weight-product-search-list.component.sass']
})
export class WeightProductSearchListComponent implements OnInit {

  weight: number;
  storeHandle: string;
  searchQuery?: string;
  isSearchBarFocused: boolean = false;
  theme?: Theme;

  searchHandler = new PaginationHandler<Product>(page =>
    this.productService.searchWeightedProducts(this.storeHandle!, this.searchQuery!, page)
  );

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private cartService: CartService,
    private storeService: StoreService,
    private location: Location,
  ) {
    this.storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
    this.weight = Number(this.route.snapshot.paramMap.get('weightInGrams')!);
  }

  async ngOnInit() {
    this.storeService.getStore(this.storeHandle).then(store => this.theme = store.theme);
    const initialQuery = this.route.snapshot.queryParams['search'];
    if (initialQuery != null) {
      await this.doSearch(this.storeHandle, this.route.snapshot.queryParams['search']);
    }
  }

  // noinspection DuplicatedCode
  async doSearch(storeHandle: string, query: string) {
    this.searchQuery = query;
    this.searchHandler.clear();
    if (query.length < 1) {
      return this.endSearch();
    }
    if (this.searchHandler.isBusy) {
      this.searchHandler.clear();
    }
    await this.searchHandler.getNext();
    this.updateUrlWithSearchQuery(query);
  }

  async endSearch() {
    this.searchQuery = undefined;
    this.searchHandler.clear();
    this.updateUrlWithSearchQuery();
  }

  async onSearchScrolled(event: any) {
    if (this.searchHandler.isBusy) {
      return;
    }
    const bottomTriggerPoint = 150;
    if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight - bottomTriggerPoint)) {
      if (this.searchHandler.hasNext()) {
        await this.searchHandler.getNext();
      }
    }
  }

  private updateUrlWithSearchQuery(query?: string) {
    const url = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: {search: query}
    }).toString();
    this.location.go(url);
  }

  doClear() {
    this.searchQuery = undefined;
    this.searchHandler.clear();
  }

  onFocusChanged(hasFocus: boolean) {
    this.isSearchBarFocused = hasFocus;
  }

  async selectProduct(product: Product) {
    await this.router.navigate(ForegroundPaths.empty());
    await this.cartService.add(this.storeHandle, product, undefined, this.weight);
  }


}
