import {ContentObserver} from '@angular/cdk/observers';
import {Subscription} from 'rxjs';
import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {OrderService} from "../../domain/order.service";


const minDurationMs = 200;
const maxDurationMs = 350;

@Component({
  selector: 'app-expanding-overlay-card',
  templateUrl: './expanding-overlay-card.component.html',
  styleUrls: ['./expanding-overlay-card.component.sass']
})


export class ExpandingOverlayCardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('controller') controller: ElementRef | undefined;
  @ViewChild('content') content: ElementRef | undefined;
  dataChanges: Subscription | undefined;

  constructor(
    private obs: ContentObserver,
    private orderService: OrderService) {
  }

  expanded: boolean = false;

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.dataChanges = this.obs.observe(this.content!.nativeElement)
      .subscribe(_ => this.onChanges());
  }

  onChanges() {
    const maxHeight = Math.min(760, document.body.scrollHeight);

    const from = (this.controller!.nativeElement as HTMLElement).scrollHeight;
    let number= 165;
    if (this.orderService.vendingCode) {
      number = -90;
    }
    const to = Math.min(maxHeight - number, (this.content!.nativeElement as HTMLElement).scrollHeight);
    (this.controller!.nativeElement as HTMLElement).style.setProperty('--max-height', to + 'px');

    const duration = Math.min(maxDurationMs, Math.max(minDurationMs, Math.max(from, to)));
    (this.controller!.nativeElement as HTMLElement).style.setProperty('--duration', duration + 'ms');

    this.expanded = to > 50;
  }

  ngOnDestroy(): void {
    this.dataChanges?.unsubscribe();
  }
}
