import {Component, OnInit} from '@angular/core';
import {Theme} from '../../../domain/models/store/theme';
import {Order} from '../../../domain/models/order/order';
import {OrderService} from '../../../domain/order.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StoreService} from '../../../domain/store.service';
import {StoreMessage} from '../../../domain/models/store/store-message';
import {ForegroundPaths} from '../../../app-routing.module';
import {PaymentService} from '../../../domain/payment.service';
import {Store} from '../../../domain/models/store/store';
import {CartService} from '../../../domain/cart.service';
import {CustomerService} from '../../../domain/customer.service';
import {isPaid} from 'src/app/utils/order.utils';
import {delay} from '../../../utils/promise.utils';

@Component({
  selector: 'app-checkout-receipt',
  templateUrl: './checkout-receipt.component.html',
  styleUrls: ['./checkout-receipt.component.sass']
})
export class CheckoutReceiptComponent implements OnInit {
  order?: Order;
  theme?: Theme;
  store?: Store;
  failed: boolean = false;
  showProgress = false;
  isDelivery: boolean = false;
  checkoutMessage?: StoreMessage;

  getOrderPaymentAttempts = 0;
  maxOrderPaymentAttempts = 25;
  orderId: string | null | undefined;
  transactionId: string | null | undefined;

  paymentVerified: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private storeService: StoreService,
    private cartService: CartService,
    private orderService: OrderService,
    private paymentService: PaymentService,
    public customerService: CustomerService,
  ) {

  }

  async ngOnInit() {
    this.orderId = this.route.snapshot.paramMap.get('orderId');
    this.transactionId = this.route.snapshot.paramMap.get('transactionId');
    const storeHandle = this.route.parent?.firstChild?.snapshot.paramMap.get('id')!;
    this.store = await this.storeService.getStore(storeHandle);
    this.theme = this.store.theme;
    this.isDelivery = this.store.storeChain.isDelivery;
    this.checkoutMessage = this.store.checkoutMessage;
    const customerId = await this.customerService.getCustomerId();

    if (this.orderId != null) {
      this.order = await this.orderService.getOrder(customerId, this.orderId).catch(_ => {
        this.failed = true;
        return undefined;
      });
    }
  }

  async retry() {
    this.failed = false;
    await this.refreshPaymentStatus();
  }

  private async refreshPaymentStatus() {
    this.showProgress = true;

    const transaction = await this.paymentService.refreshPaymentStatus(this.order!.id);

    if (transaction.paymentMethod === 'mastercard' || transaction.paymentMethod === 'checkout') {
      await this.paymentService.complete(this.orderId!, this.transactionId!);
    }

    this.getOrderPaymentAttempts++;
    this.paymentVerified = isPaid(transaction);
    if (!this.paymentVerified) {
      if (this.getOrderPaymentAttempts <= this.maxOrderPaymentAttempts) {
        await delay(1000);
        if (this.order == null && this.orderId != null) {
          const customerId = await this.customerService.getCustomerId();
          this.order = await this.orderService.getOrder(customerId, this.orderId).catch(_ => {
            this.failed = true;
            return undefined;
          });
        }
        await this.refreshPaymentStatus();
      } else {
        this.showProgress = false;
        this.failed = true;
      }
    } else if (this.paymentVerified) {
      this.cartService.clearCart(this.store!.handle);
    }
    this.showProgress = false;
  }

  async showReceipt() {
    await this.router.navigate(ForegroundPaths.orderDetails(this.orderId!));
  }

  openSignIn() {
    if (this.order != null) {
      this.orderService.setLastKnownOrder(this.order.id);
    }
    this.customerService.openLoginDialog();
  }
}

