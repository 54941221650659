import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConfig} from '../app.config';
import {CartRequest} from './models/order/cart.request';
import {CartResponse} from './models/order/cart.response';
import {Order} from '../domain/models/order/order';
import {map} from 'rxjs/operators';
import {Page, Paginated} from './models/paginated';
import {GateCode} from '../domain/models/order/gate-code';

@Injectable({
  providedIn: 'root',
})
export class OrderProvider {

  constructor(private http: HttpClient) {
  }

  getOrders(customerId: string, page?: Page): Observable<Paginated<Order>> {
    return this.http.get<Order[]>(`${AppConfig.getApiUrl()}/orders`,
      {
        headers: {
          'auth-key': AppConfig.authKey,
          'customer-id': customerId,
        },
        params: page?.toParams(),
        observe: 'response',
      }).pipe(map(r => Paginated.fromResponse<Order>(r)));
  }

  getOrder(customerId: string, orderId: string): Observable<Order> {
    return this.http.get<Order>(
      `${AppConfig.getApiUrl()}/orders/${orderId}`,
      {
        headers: {
          'auth-key': AppConfig.authKey,
          'customer-id': customerId,
        },
      },
    );
  }

  getOrderGateCode(customerId: string, orderId: string): Observable<GateCode> {
    return this.http.get<GateCode>(
      `${AppConfig.getApiUrl()}/gate-code`,
      {
        headers: {
          'auth-key': AppConfig.authKey,
          'customer-id': customerId,
        },
        params: {orderId},
      },
    );
  }


  getCart(customerId: string, request: CartRequest): Observable<CartResponse> {
    return this.http.post<CartResponse>(
      `${AppConfig.getApiUrl()}/orders/cart`,
      request, {
        headers: {
          'auth-key': AppConfig.authKey,
          'customer-id': customerId,
        },
      },
    );
  }

  transferOrder(transferOrderId: string, oldCustomerId: string, newCustomerId: string) {
    return this.http.post(
      `${AppConfig.getApiUrl()}/orders/transfer`,
      null, {
        headers: {
          'auth-key': AppConfig.authKey,
          'customer-id': oldCustomerId,
        },
        params: new HttpParams({
          fromObject: {
            orderId: transferOrderId,
            toCustomerId: newCustomerId,
          },
        }),
      },
    );
  }

  setOrderDelivered(orderId: string) {
    return this.http.post(
      `${AppConfig.getApiUrl()}/orders/${orderId}/delivered`,
      null,
      {
        headers: {
          'auth-key': AppConfig.authKey,
        },
      },
    );
  }
}
