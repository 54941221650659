
<div class="shopping-bag-page-close">
  <div>
    <mat-icon (click)="closeForeground()">
      close
    </mat-icon>
  </div>
</div>
<div class="shopping-bag-title">
  <h2 [ngStyle]="{color: theme?.title}">
    {{ 'CART.SHOPPINGBAG.selectbag' | translate }}
  </h2>
</div>


<ng-container  *ngFor="let product of shoppingBag?.data">
  <div class="shopping-bag-details" (click)="openProductDetails(product.handle)">
    <div class="shopping-bag-image">
      <img src="{{getProductImage(product.images, 2)}}" alt="product image">
    </div>
    <h4 [ngStyle]="{color: theme?.title}">{{product.name}}
      ({{toFriendlyPrice(product.dimensions[0].prices[0].amount)}}{{ 'CART.SHOPPINGBAG.priceunit' | translate }}) </h4>

    <button class="shopping-bag-add-button" [ngStyle]="{color: theme?.title}"
            (click)="addToCart(product,product.dimensions[0])">
      {{ 'CART.SHOPPINGBAG.select' | translate }}
    </button>
  </div>
</ng-container>





