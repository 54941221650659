import {Component, Inject, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {ConfirmDialog} from '../../confirm/confirm.dialog';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {Theme} from '../../../domain/models/store/theme';
import {StoreService} from '../../../domain/store.service';
import {Store} from '../../../domain/models/store/store';

@Component({
  selector: 'app-amountsheet',
  templateUrl: './amountsheet.dialog.html',
  styleUrls: ['./amountsheet.dialog.sass']
})
export class AmountsheetDialog implements OnInit {
  private readonly initialValue?: number;
  value: number = 0;
  theme?: Theme;
  store?: Store;

  constructor(private bottomSheetRef: MatBottomSheetRef<AmountsheetDialog>,
              private translateService: TranslateService,
              private dialogService: MatDialog,
              private storeService: StoreService,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data?: number) {
    this.initialValue = data;
    if (this.initialValue != null) {
      this.value = this.initialValue;
    }
  }

  async ngOnInit(): Promise<void> {
    if (this.storeService.storeId) {
      this.store = await this.storeService.getStore(this.storeService.storeId);
      this.theme = this.store.theme;
    }
  }

  increase() {
    this.value++;
  }

  async decrease() {
    if (this.value > 1) {
      this.value--;
      return;
    }
    const result = await this.confirmRemoval();
    if (result) {
      this.bottomSheetRef.dismiss(0);

    }
  }

  private async confirmRemoval(): Promise<boolean> {
    const text = await this.translateService.get('DIALOG.REMOVEPRODUCT.content').toPromise();
    const confirm = await this.translateService.get('DIALOG.REMOVEPRODUCT.submit').toPromise();
    const dialogRef = this.dialogService.open(ConfirmDialog, {
      width: '250px',
      data: {
        text,
        confirm,
      }
    });
    const result = await dialogRef.afterClosed().toPromise();

    return result === true;
  }

  removeProduct() {
    this.bottomSheetRef.dismiss(0);
  }

  setQuantity() {
    this.bottomSheetRef.dismiss(this.value);
  }
}
