<div>
  <h2 class="backend-counter-title">Behind counter products</h2>
  <div>
  <div class="backend-counter-product-list">
      <div class="backend-counter-product" *ngFor="let product of products?.data">
          <app-product-card [product]="product"
                            [store]="store"
                            (onClick)="openProduct(product.handle)"
                            [cultureName]="store!.cultureName!"
                            [currencyCode]="store!.currencyCode!">
          </app-product-card>
      </div>
  </div>
  </div>
</div>





