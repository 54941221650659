import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {CustomerService} from '../../../domain/customer.service';
import {MatDialog} from '@angular/material/dialog';
import {MessageDialog} from '../../../dialogs/message/message.dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {DoorService} from '../../../domain/door.service';
import {Door} from '../../../domain/models/door/door';
import {Title} from '@angular/platform-browser';
import {ImageUtils} from '../../../utils/image.utils';
import {delay} from '../../../utils/promise.utils';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-door',
  templateUrl: './door.component.html',
  styleUrls: ['./door.component.sass'],
})
export class DoorComponent implements OnInit, OnDestroy {
  hasValidAuth = false;
  isOpeningDoor = false;
  notFound = false;
  maxTokenAge = 295; // <5 minutes. This should match the backend

  door?: Door;
  storeLogo?: string;

  authSubscription?: Subscription;

  constructor(private el: ElementRef<HTMLDivElement>,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router,
              private titleService: Title,
              private doorService: DoorService,
              private customerService: CustomerService,
              private translationService: TranslateService,
  ) {
  }

  async ngOnInit() {
    const doorId = this.route.snapshot.paramMap.get('id');
    if (doorId) {
      try {
        this.door = await this.doorService.getDoor(doorId);
      } catch (error) {
        if (error.status == 404) {
          this.handleDoorNotFound();
        }
        throw error;
      }
    } else {
      this.handleDoorNotFound();
      throw Error('Missing door id route param');
    }

    if (this.door) {
      this.authSubscription = this.customerService.isSignedIn$.subscribe((isSignedIn) => {
        if (isSignedIn) {
          this.hasValidAuth = this.validateToken();
        } else {
          this.hasValidAuth = false;
        }
      });

      if (this.door?.storeImage) {
        this.storeLogo = ImageUtils.getImageUrl(this.door.storeImage, 200, false) ?? '/assets/images/LogoFygi.svg';
      } else {
        this.storeLogo = '/assets/images/LogoFygi.svg';
      }

      this.titleService.setTitle(`${this.door?.welcomeTitle ?? this.translationService.instant('DOOR.defaultTitle')} | FYGI`);
    }
  }

  ngOnDestroy() {
    this.authSubscription?.unsubscribe();
  }

  openLoginDialog() {
    this.customerService.openLoginDialog(undefined, this.door?.storeHandle).afterClosed().subscribe(() => {
      this.hasValidAuth = this.validateToken();
      if (this.hasValidAuth) {
        delay(200).then(() => {
          const doorEl = this.el.nativeElement.firstElementChild;
          doorEl?.scrollTo({top: doorEl.scrollHeight, behavior: 'smooth'});
        });
      }
    });
  }

  async openDoor() {
    if (this.isOpeningDoor) {
      return;
    } else if (this.door == null) {
      this.handleDoorNotFound();
      throw Error('Missing door object');
    }

    this.isOpeningDoor = true;
    try {
      const result = await this.doorService.openDoor(this.door.id);
      await this.router.navigate([`store/${result.storeHandle}`]);
    } catch (error) {
      if (error.status == 401) {
        this.hasValidAuth = false;
        this.openLoginDialog();
      } else if ([404, 410, 423].includes(error.status)) {
        this.handleDoorNotFound();
      }
      throw error;
    } finally {
      this.isOpeningDoor = false;
    }
  }

  openHelp() {
    // TODO: Need to update texts
    this.dialog.open(MessageDialog, {
      data: {
        title: this.translationService.instant('DOOR.helpTitle'),
        text: this.translationService.instant('DOOR.helpText'),
        confirm: this.translationService.instant('DOOR.helpClose'),
      },
    });
  }

  validateToken() {
    return Boolean(this.customerService.getAndValidateToken(true, this.maxTokenAge));
  }

  handleDoorNotFound() {
    this.notFound = true;
    this.isOpeningDoor = false;
    this.hasValidAuth = false;
  }
}
