import {Component, OnInit} from '@angular/core';
import {StoreService} from '../../../domain/store.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StoreShallow} from '../../../domain/models/store/store';
import {Image} from '../../../domain/models/product/image';
import {ImageUtils} from '../../../utils/image.utils';
import {Distanced, DistanceUtils} from '../../../utils/distance.utils';
import {Observable} from 'rxjs';
import {LocalStorageService} from 'ngx-webstorage';

@Component({
  selector: 'app-chain-store-list',
  templateUrl: './chain-store-list.component.html',
  styleUrls: ['./chain-store-list.component.sass']
})
export class ChainStoreListComponent implements OnInit {

  stores: Distanced<StoreShallow>[] | null = null;
  private readonly chainHandle: string | null;
  private readonly productHandle: string | null;

  constructor(
    private storeService: StoreService,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: LocalStorageService) {
    this.chainHandle = this.route.snapshot.paramMap.get('chainHandle');
    this.productHandle = this.route.snapshot.paramMap.get('productHandle');
  }

  async ngOnInit() {
    this.stores = await this.storeService.getChainStores(this.chainHandle!);

    if (this.productHandle != null) {
      this.storageService.store('pendingAddCartProduct', this.productHandle);
    }
  }

  async openStore(store: Distanced<StoreShallow>) {
    await this.router.navigateByUrl(`/store/${store.handle}`);
  }

  async onLocateClicked() {
    const tempStores = this.stores;
    this.stores = null;
    if (navigator.permissions) {
      const geoLocationPermission = await navigator.permissions.query({name: 'geolocation'});

      if (geoLocationPermission.state == 'prompt') {
        const location = await this.getLocation().toPromise().catch(_ => {
          return undefined;
        });

        if (location != undefined) {
          this.sortStores(location, tempStores!);
        } else {
          this.stores = tempStores;
        }
      } else if (geoLocationPermission.state == 'granted') {
        const location = await this.getLocation().toPromise().catch(_ => {
          return undefined;
        });

        if (location != undefined) {
          this.sortStores(location, tempStores!);
        } else {
          this.stores = tempStores;
        }
      }
    } else {
      const location = await this.getLocation().toPromise().catch(_ => {
        return undefined;
      });

      if (location != undefined) {
        this.sortStores(location, tempStores!);
      } else {
        this.stores = tempStores;
      }
    }
  }

  private sortStores(location: GeolocationCoordinates, tempStores: Distanced<StoreShallow>[]) {
    tempStores.forEach(store => {
      store.distance = DistanceUtils.calculateDistance(location.latitude, location.longitude, store.latitude!, store.longitude!);
    });

    this.stores = tempStores.sort((a, b) => a.distance! - b.distance!)!;
  }

  private getLocation(): Observable<GeolocationCoordinates> {
    return new Observable(obs => {
      navigator.geolocation.getCurrentPosition(
        success => {
          obs.next(success.coords);
          obs.complete();
        },
        error => {
          obs.error(error);
        }
      );
    });
  }

  getStoreImage(image: Image | undefined, size: number) {
    return ImageUtils.getImageUrl(image!, size);
  }
}
