<div class="amount-sheet">
  <h3 class="amount-sheet-title">{{ 'CART.AMOUNT.title' | translate }}</h3>
  <div class="amount-sheet-selector">
    <app-quantity-selector
      [quantity]="value ?? 0"
      (increase)="increase()"
      (decrease)="decrease()"
      (quantitySubmitted)="value = $event"
      [theme]="theme">
    </app-quantity-selector>
  </div>
  <div class="amount-sheet-buttons">
    <button class="amount-sheet-button-remove" [ngStyle]="{background: theme?.payForeground, color: theme?.payBackground}"
            (click)="removeProduct()">{{ 'CART.AMOUNT.remove' | translate }}</button>
    <button class="amount-sheet-button-add" [ngStyle]="{background: theme?.payForeground, color: theme?.payBackground}"
            (click)="setQuantity()">{{ 'CART.AMOUNT.choose' | translate }}</button>
  </div>
</div>

