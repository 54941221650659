import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ForegroundPaths} from "../../../app-routing.module";
import {ActivatedRoute, Router} from "@angular/router";
import {Theme} from '../../../domain/models/store/theme';
import {Cart, OrderLine} from "../../../domain/models/order/cart";
import {Store} from "../../../domain/models/store/store";
import {StoreService} from "../../../domain/store.service";
import {ProductService} from "../../../domain/product.service";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {ProductOrderLine} from "../../../domain/models/order/order-line";
import {CartService} from "../../../domain/cart.service";
import {Product} from "../../../domain/models/product/product";
import {Paginated} from "../../../transport/models/paginated";
import {GTagService} from "../../../domain/gtag.service";
import {Image} from "../../../domain/models/product/image";
import {ImageUtils} from "../../../utils/image.utils";
import {Dimension} from "../../../domain/models/product/dimension";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {PriceUtils} from "../../../utils/price.utils";

@Component({
  selector: 'app-shopping-bag',
  templateUrl: './shopping-bag.component.html',
  styleUrls: ['./shopping-bag.component.sass']
})
export class ShoppingBagComponent implements OnInit {
  store?: Store;
  cart?: Cart;
  @Input() isBusy: boolean = false;
  @Input() theme: Theme | undefined;
  @Output() quantityClicked = new EventEmitter<ProductOrderLine>();


  shoppingBag?: Paginated<Product> | void


  constructor(private router: Router,
              private storeService: StoreService,
              private route: ActivatedRoute,
              private productService: ProductService,
              private cartService: CartService,
              private bottomSheet: MatBottomSheet,
              private toastr: ToastrService,
              private translateService: TranslateService,
  ) {
  }


  async ngOnInit(): Promise<void> {
    const storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
    this.store = await this.storeService.getStore(storeHandle);
    this.theme = this.store.theme;
    this.shoppingBag = await this.productService.getShoppingBagProducts(storeHandle)
  }

  async closeForeground() {
    await this.router.navigate(ForegroundPaths.cart());
  }


  getProductImage(images: Image[] | undefined, size: number): string | undefined {
    if (images != undefined) {
      return ImageUtils.getImageUrl(images[0], size);
    }
    return undefined;
  }

  async openProductDetails(producthandle: string) {
    if (producthandle != null) {
      await this.router.navigate(ForegroundPaths.product(producthandle));
    }
  }


  async addToCart(product: Product, dimension?: Dimension): Promise<any> {
    this.isBusy = true;
    if (this.store != null) {
      await this.router.navigate(ForegroundPaths.cart());
      await new Promise(resolve => setTimeout(resolve, 80));
      await this.cartService.add(this.store.handle, product, dimension?.id)
    }
    this.isBusy = false;
  }


  toFriendlyPrice(price: number): string {
    return PriceUtils.toPriceShoppingBag(price);
  }



}
