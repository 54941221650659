import {Component} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {StoreService} from '../../domain/store.service';
import {Store} from '../../domain/models/store/store';
import {Theme} from '../../domain/models/store/theme';

@Component({
  templateUrl: './ean-input.dialog.html',
  styleUrls: ['./ean-input.dialog.sass']
})
export class EanInputDialog {
  inputText?: string;
  store?: Store;
  theme?: Theme;

  constructor(public dialogRef: MatDialogRef<EanInputDialog>,
              private storeService: StoreService) {
  }

  async ngOnInit(): Promise<void> {
    if (this.storeService.storeId) {
      this.store = await this.storeService.getStore(this.storeService.storeId);
      this.theme = this.store.theme;
    }
  }

  close(text?: string) {
    this.dialogRef.close(text);
  }

}
