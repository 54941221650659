<div class="root">
  <div *ngIf="!showExternalPaymentFlow">
    <div>
      <img class="cart-details-avatar-image" src="assets/images/avatar.svg" alt="avatar image">
      <h5 class="cart-details-avatar-title" [ngStyle]="{color: theme?.title}">
        {{ 'CART.avatarTitle' | translate }}
      </h5>
    </div>

    <h2 class="cart-details_total-title" [ngStyle]="{color: theme?.title}">
      {{ 'CART.yourOrder' | translate }}
    </h2>

    <div *ngIf="cart?.orderLines != null" class="cart-details_order-lines">
      <ol class="scroll-container" #scrollContainer>
        <ng-container *ngFor="let orderLine of cart!.orderLines">
          <li *ngIf="!isOrderLineValid(orderLine)" class="invalid">
            <app-cart-item [orderLine]="orderLine"
                           [isBusy]="isCartBusy"
                           [theme]="theme"
                           [dropShadow]="true"
                           (quantityClicked)="openBottomSheet($event)"
                           (removeClicked)="removeOrderLine($event)"
                           [cultureName]="cultureName"
                           [currencyCode]="currencyCode"
                           [relatedProducts]="relatedProducts"
            >
            </app-cart-item>
          </li>
        </ng-container>
        <ng-container *ngFor="let orderLine of cart!.orderLines">
          <li *ngIf="isOrderLineValid(orderLine)">
            <app-cart-item [orderLine]="orderLine"
                           [isBusy]="isCartBusy"
                           [theme]="theme"
                           [dropShadow]="true"
                           (quantityClicked)="openBottomSheet($event)"
                           (fulfillmentSelected)="onFulfillmentSelected(orderLine, $event)"
                           [cultureName]="cultureName"
                           [currencyCode]="currencyCode"
                           [relatedProducts]="relatedProducts">
              </app-cart-item>
          </li>
          <div *ngIf="isOverflown(scrollContainer)" class="overflow"></div>
        </ng-container>
        <ng-container *ngFor="let orderLine of offlineCart?.orderLines">
          <li>
            <app-offline-cart-item [orderLine]="orderLine"
                                   (quantityClicked)="openOfflineBottomSheet($event)"
                                   [isBusy]="isCartBusy"
                                   [theme]="theme"
                                   [dropShadow]="true">
            </app-offline-cart-item>
          </li>
          <div *ngIf="isOverflown(scrollContainer)" class="overflow"></div>
        </ng-container>
      </ol>
    </div>

    <ng-container *ngIf="this.currentAdjustmentType">
      <div class="adjustment-type">
        <h5 class="adjustment-type-title" >
          Spis her eller ta med:
        </h5>
        <button class="adjustment-type-button"
                [ngStyle]="{'background-color': theme?.payForeground}"
                (click)="requestAdjustment()">
          {{getAdjustmentTypeTitle()}}
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="shoppingBag?.data?.length != 0 && offlineCart == null">
      <div class="cart-details-shopping-bag" *ngIf="cart != null">
        <h5 class="cart-details-shopping-bag-title" [ngStyle]="{color: theme?.title}">
          {{ 'CART.SHOPPINGBAG.title' | translate }}
        </h5>
        <button class="cart-details-shopping-bag-button" [ngStyle]="{'background-color': theme?.payForeground}"
                (click)="openShoppingBags()">
          {{ 'CART.SHOPPINGBAG.add' | translate }}
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="this.discountCodeEnabled">
      <div class="cart-details-shopping-bag" *ngIf="cart != null">
        <input [(ngModel)]="inputText" matInput class="discount-input"
               placeholder="{{ 'DISCOUNT.discount'  | translate }}">
        <button class="cart-details-shopping-bag-button" [ngStyle]="{'background-color': theme?.payForeground}"
                (click)="addDiscount(inputText)">
          {{ 'DISCOUNT.add' | translate }}
        </button>
      </div>
    </ng-container>


    <div *ngIf="offlineCart" (click)="tryCartConnect()" class="cart-details_offline-banner">
      <mat-spinner *ngIf="showOfflineCartProgress" diameter="22"></mat-spinner>
      <span *ngIf="!showOfflineCartProgress">Offline mode - press to refresh</span>
    </div>

    <div class="cart-details_total-summary" *ngIf="cart != null">
      <div>
        <h2 class="cart-details_total-title" [ngStyle]="{color: theme?.title}">
          {{ 'CART.sum' | translate }}
        </h2>
        <h5 class="cart-details_total-count" [ngStyle]="{color: theme?.title}">
          {{ 'CART.products' | translate:'{count: ' + getProductCount(cart) + '}' }}
        </h5>
      </div>
      <h2 class="cart-details_total-price" [ngStyle]="{color: theme?.price}">
        {{ toPrice(cart.sum) | currency:currencyCode:'symbol-narrow': '':cultureName}}
      </h2>
    </div>

    <ng-container *ngIf="requiresShipmentAddress">
      <div class="spacer">
        <svg width="100%" height="1">
          <line x1="0" y1="0.5" x2="100%" y2="0.499972" stroke="#DFE7EB" stroke-dasharray="6 6"></line>
        </svg>
      </div>
      <div class="edit-address">
        <button *ngIf="!cart?.shippingAddress"
                class="cart-details-shipping-button"
                (click)="openShipmentSheet()"
                [ngStyle]="{'background-color': theme?.payForeground}">
          {{ 'SHIPPING.addAddress' | translate }}
        </button>
        <div *ngIf="cart?.shippingAddress" class="cart-details-address-group" (click)="openShipmentSheet()">
          <div class="cart-details-address-email">{{ cart?.shippingAddress?.emailAddress }}</div>
          <div class="cart-details-address-name">{{ cart?.shippingAddress?.fullName }}</div>
          <div>
            <span class="cart-details-address-addressOne">{{ cart?.shippingAddress?.addressLine1 }}, </span>
            <span class="cart-details-address-AddressTwo">{{ cart?.shippingAddress?.addressLine2 }}</span>
          </div>
          <div class="cart-details-pcc-group">
            <span class="cart-details-address-postalCode">{{ cart?.shippingAddress?.postalCode }} </span>
            <span class="cart-details-address-city">{{ cart?.shippingAddress?.city }}</span>
          </div>
          <div class="cart-details-address-country">{{ cart?.shippingAddress?.countryName }}</div>
          <div class="cart-details-address-change">
            <span>{{ 'SHIPPING.edit' | translate }}</span>
            <img src="/assets/images/edit.svg" alt="avatar image" height="20" width="20">
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="error">
      <ng-container *ngTemplateOutlet="errorTemplate"></ng-container>
    </ng-container>

    <div *ngIf="directPayment?.logoUrl" class="direct-payment">
      <button class="direct-payment-button" (click)="goPayment()">
        <img class="direct-payment-logo" [src]="directPayment?.logoUrl" [alt]="directPayment?.name" width="250" height="55">
      </button>
    </div>
    <div *ngIf="!directPayment?.logoUrl" class="pay-button" (click)="goPayment()" [ngStyle]="{'background-color': theme?.payForeground}">
      {{ 'CART.paymentMethod' | translate }}
    </div>
  </div>


  <ng-template #loader>
    <ng-lottie
      class="cart-details_payment-loading"
      height="50px"
      width="50px"
      [options]="{
                  path: './assets/animations/fygiloader3.json',
                  renderer: 'svg',
                  autoplay: true,
                  loop: true
              }">
    </ng-lottie>
  </ng-template>

  <ng-template #errorTemplate>
    <div *ngIf="this.error == 'The item is out of stock';" class="cart-details_error">
      <p class="checkout-receipt_header_title">
        {{this.error}}
      </p>
      <p class="checkout-receipt_header-description">
        {{ 'ERROR.GENERIC.requestRetry' | translate }}
      </p>
    </div>
    <div *ngIf="this.error != 'The item is out of stock';" class="cart-details_error">
      <p class="checkout-receipt_header_title">
        {{ 'ERROR.GENERIC.title' | translate }} {{ 'ERROR.GENERIC.description' | translate }}
      </p>
      <p class="checkout-receipt_header-description">
        {{ 'ERROR.GENERIC.requestRetry' | translate }}
      </p>
    </div>

    <div *ngIf="showExternalPaymentFlow">
      <div id="dropin-container"></div>
      <button class="cancel-button" (click)="resetForm()">
        {{ 'DIALOG.cancel' | translate }}
      </button>
    </div>
  </ng-template>
</div>


