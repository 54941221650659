<div>
  <div class="age-check-page" *ngIf="!minimized else minifiedTemplate">
      <img class="age-check-avatar-image" src="/assets/images/thumbsup.svg" alt="avatar image" width="100" height="100">
      <div class="age-check-avatar-title" [ngStyle]="{color:theme?.title}">
        {{ 'AGELIMIT.ageLimitMessage' | translate }}
      </div>
      <div>
        <button class="age-check-button" (click)="scanqr()">
          {{ 'AGELIMIT.scan' | translate }}
        </button>
      </div>
  </div>

  <ng-template #minifiedTemplate>
    <div class="age-check-minimized-title"
       [ngStyle]="{color: theme?.title}"
       (click)="stopScanForStore()">
      {{ 'AGELIMIT.ageLimitCheck' | translate }}
    </div>
  </ng-template>
</div>
