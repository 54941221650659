<div class="root">
  <div class="quantity-selector">
    <button [disabled]="isBusy" class="quantity-selector__button negative" [ngClass]="{'isBusy' : isBusy}"
            [ngStyle]="{background: theme?.payForeground, color: theme?.payBackground}"
            (click)="decrease.emit()">
      -
    </button>
    <input class="quantity-selector__button center" #input inputmode="numeric"
           [ngStyle]="{background: theme?.background, color: theme?.foreground}" (blur)="onSubmit(input.value)"
           (keyup.enter)="onSubmit(input.value)"
           [ngModel]="quantity">
    <button [disabled]="isBusy" class="quantity-selector__button positive" [ngClass]="{'isBusy' : isBusy}"
            [ngStyle]="{background: theme?.payForeground, color: theme?.payBackground}"
            (click)="increase.emit()">
      +
    </button>
  </div>
</div>
