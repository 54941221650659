<div class="door">
  <div class="door__header">
    <app-image-fading class="door__logo" [source]="storeLogo" [animateLoading]="false"></app-image-fading>
    <div class="door__help">
      <button class="door__help-btn" type="button" (click)="openHelp()">
        <img src="/assets/images/help.svg" alt="Help" height="24" width="24">
      </button>
    </div>
  </div>
  <ng-container *ngIf="!notFound, else notFoundTpl">
    <div class="door__body">
      <img class="door__illustration" src="/assets/images/digitalshopping.png" alt="Access store" width="609" height="435">
      <h1 class="door__title" [ngStyle]="{color: door?.theme?.title}">
        <ng-template [ngIf]="door">
          {{ door?.welcomeTitle ?? 'DOOR.defaultTitle' | translate }}
        </ng-template>
        <app-gradient-loader *ngIf="!door"></app-gradient-loader>
      </h1>
      <p class="door__text">
        <ng-template [ngIf]="door">
          {{ door?.welcomeMessage ?? 'DOOR.defaultText' | translate }}
        </ng-template>
        <ng-template [ngIf]="!door">
          <app-gradient-loader></app-gradient-loader>
          <app-gradient-loader></app-gradient-loader>
        </ng-template>
      </p>
      <ng-template [ngIf]="door">
        <div class="door__spacer"></div>
        <ng-container *ngIf="!hasValidAuth, else openDoorBtn">
          <button class="door__login-btn"
                  type="button"
                  [ngStyle]="{background: door.theme?.payForeground, color: door.theme?.payBackground}"
                  (click)="openLoginDialog()">
            {{ 'DOOR.signIn' | translate }}
          </button>
        </ng-container>
      </ng-template>
    </div>
  </ng-container>
</div>

<ng-template #openDoorBtn>
  <button class="door__open-btn"
          type="button"
          [ngClass]="{'door__open-btn--loading': isOpeningDoor}"
          [ngStyle]="{background: door?.theme?.payForeground, color: door?.theme?.payBackground}"
          (click)="openDoor()">
    <ng-template [ngIf]="!isOpeningDoor">
      {{ 'DOOR.openDoor' | translate }}
    </ng-template>
    <ng-template [ngIf]="isOpeningDoor">
      <mat-spinner diameter="40">{{ 'LOGIN.loading' | translate }}</mat-spinner>
    </ng-template>
  </button>
</ng-template>

<ng-template #notFoundTpl>
  <div class="door__body" style="justify-content: center">
    <img class="door__illustration" src="/assets/images/store-invalid.svg" alt="Not found" width="250" height="180">
    <h1 class="door__title">{{ 'DOOR.ERROR.notFoundTitle' | translate }}</h1>
    <p class="door__text">{{ 'DOOR.ERROR.notFoundText' | translate }}</p>
  </div>
</ng-template>
