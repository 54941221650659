<div class="root">
  <div class="adjustment-type-header">{{ 'STORE.eatOptions' |  translate}}</div>
  <div class="adjustment-type-buttons">
    <button *ngFor="let adjustment of adjustmentTypes let i = index" (click)="typeChosen(adjustment)" class="adjustment-type-button"
            [ngStyle]="i == adjustmentTypes!.length-2? {background: theme?.payForeground, color: theme?.payBackground} :
            {background: 'white',color: '#0A1237',border: '#0A1237 solid 1px'}">
      {{getTypeName(adjustment.name)}}
    </button>
  </div>
</div>
