<div class="product-details_image-container">
  <swiper effect="fade" *ngIf="product"
          class="swiper-container"
          [config]="{
            pagination: {
              el: '.swiper-pagination',
              clickable: true
            },
            spaceBetween: 30
          } //noinspection InvalidExpressionResultType">

    <ng-template swiperSlide *ngIf="!product.images || product.images.length == 0">
      <img class="product-details_image" src="{{getFallBackImage()}}" alt="missing-product-image">
    </ng-template>

    <ng-template swiperSlide *ngFor="let image of product.images; index as i">
      <div *ngIf="product.images.length != 0">
        <app-image-fading class="product-details_image"
                          [animateLoading]="false"
                          source="{{getImage(image, 600)}}">
        </app-image-fading>
      </div>
    </ng-template>
  </swiper>


  <div class="swiper-pagination" *ngIf="product != null && product.images != null && product.images.length > 1"></div>
</div>

<div class="product-details_description-container">
  <div *ngIf="product != null, else loading">
    <h5 [ngStyle]="{color: theme?.foreground}" class="product-details_vendor">{{product.vendor}}</h5>
    <h2 [ngStyle]="{color: theme?.title}" class="product-details_title">{{product.name}}</h2>
    <h5 *ngIf="selectedDimension != null" [ngStyle]="{color: theme?.title}"
        class="product-details_barcode">{{selectedDimension.articleNumber}}</h5>
    <h5 *ngIf="isStock" class="product-details_stock">{{ 'PRODUCTDETAILS.stock' | translate}}
      : {{availabilityCount}}</h5>
    <ng-container *ngIf="product.description">
      <div class="product-details_description">
        <p [ngStyle]="{color: theme?.foreground}"
           [ngClass]="{'minimized': !showFullDescription}"
           [innerHTML]="product.description | safeHtml"
           class="product-details_description-text"
           #details></p>
        <div *ngIf="!showFullDescription && isOverflown(details)"
             class="overflow"
             (click)="showFullDescription=true">
        </div>
      </div>

      <div class="product-details_expand">
        <mat-icon *ngIf="isOverflown(details) || showFullDescription"
                  (click)="showFullDescription = !showFullDescription">
          {{showFullDescription ? 'expand_less' : 'expand_more'}}
        </mat-icon>
      </div>
    </ng-container>

    <div class="product-details_option" *ngIf="selectedDimension != null && product.options != null && product.options.length > 0">
      <div *ngFor="let option of product.options; let i = index">
        <div *ngIf="option.values.length > 1">
          <span class="product-details_option-name"
                [ngStyle]="{color: store?.theme?.title}">
            {{option.name}}:
          </span>
          <div class="product-details_option-select"
               [ngStyle]="{background: store?.theme?.background, color: store?.theme?.title}"
               (click)="selectDimension(option, i)">
            <span>{{getOptionValue(selectedDimension, i)}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="product-details_price" [ngStyle]="{color: theme?.price}">
      <span *ngIf="hasProductCampaign(campaigns)">
        {{toFriendlyPrice(getCheapestPrice(product, campaigns))}}
      </span>
      <span [ngClass]="{'original-price': hasProductCampaign(campaigns)}">
        {{  this.price | currency:currencyCode:'symbol-narrow': '':cultureName}}
        <span class="ageLimit" *ngIf="product.ageGroup">
          {{product.ageGroup.name}}+
        </span>
        <ng-container *ngIf="isUnitPricing">
        <span style="font-size: 15px">
          ({{ this.unitPrice | currency:currencyCode:'symbol-narrow': '':cultureName }} / {{this.unitAbbreviation}})
        </span>

          </ng-container>
      </span>
      <span style="padding-left: 10px" *ngIf="product.isWeight">/ kg</span>
      <div class="product-details_campaigns">
        <div *ngFor="let campaign of campaigns"
             [ngStyle]="{
               color: theme?.actionBackground,
               backgroundColor: theme?.actionForeground,
               borderColor: theme?.actionBackground
             }"
             class="product-details_campaign">
          {{campaign.name}}
        </div>
      </div>
    </div>

    <div class="product-details-deposit-fee" *ngIf="this.selectedDimension != null && this.selectedDimension.requiredDimension">
      <div [ngStyle]="{color: theme?.price}" *ngIf="this.selectedDimension.requiredDimension.prices[0].amount != 0">
        + {{this.selectedDimension.requiredDimension.name}}
        {{this.selectedDimension.requiredDimension.prices[0].amount / 100 | currency:currencyCode:'symbol-narrow': '':cultureName}}
      </div>
    </div>

    <div class="product-details_purchase" *ngIf="product != null">
      <div [ngStyle]="{'background-color': theme?.payForeground, color: theme?.payBackground}"
           [ngClass]="{'isBusy': isBusy}"
           (click)="addToCart( store?.handle!, product,  selectedDimension)"
           class="product-details_purchase-button">
        {{ 'PRODUCTDETAILS.addToCart' | translate }}
      </div>
    </div>

    <div *ngIf="relatedProducts.length > 0" class="product-details_related">
      <h3 [ngStyle]="{color: theme?.title}" class="product-details_related-title">
        {{store?.relatedProductsTitle}}
      </h3>

      <div class="product-details_related-container">
        <app-product-card *ngFor="let relatedProduct of relatedProducts"
                          [product]="relatedProduct"
                          (onClick)="openProduct(relatedProduct.handle)"
                          [store]="store"
                          [hasShadow]="true"
                          [cultureName]="cultureName"
                          [currencyCode]="currencyCode"
                          class="product-details_related-card">
        </app-product-card>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <app-gradient-loader></app-gradient-loader>
  <app-gradient-loader style="margin-top: 10px"></app-gradient-loader>
</ng-template>
