<div style="height: 20px"></div>
<div class="weight">
  <div class="weight_description">
    <h4 class="weight_title" [ngStyle]="{color: theme?.title}">{{'WEIGHT.chooseProductTitle' | translate}}</h4>
    <h1 class="weight_amount" [ngStyle]="{color: theme?.title}">{{weight}}</h1>
  </div>

  <div class="weight_order-line">
    <ng-container *ngFor="let orderLine of availableOrderLines">
      <app-weight-select-item
        [orderLine]="orderLine"
        [storeHandle]="storeHandle"
        (onSelected)="onSelected($event)">
      </app-weight-select-item>
    </ng-container>
  </div>
</div>
<div class="weight_search">
  <p class="weight_search_text" [ngStyle]="{color: theme?.title}">
    {{ 'WEIGHT.noProducts1' | translate}} <br> {{ 'WEIGHT.noProducts2' | translate}}
  </p>
  <button class="weight_search_button" [ngStyle]="{color: theme?.actionBackground}">
    <mat-icon class="weight_search_button_icon" (click)="searchProductRoute()" [ngStyle]="{color: theme?.actionForeground}">
      search
    </mat-icon>
  </button>
</div>

