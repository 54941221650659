import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProductOrderLine} from '../../domain/models/order/order-line';
import {ProductService} from '../../domain/product.service';
import {Product} from '../../domain/models/product/product';
import {Theme} from '../../domain/models/store/theme';

@Component({
  selector: 'app-weight-select-item',
  templateUrl: './weight-select-item.component.html',
  styleUrls: ['./weight-select-item.component.sass']
})
export class WeightSelectItemComponent implements OnInit {
  @Input() orderLine?: ProductOrderLine;
  @Input() storeHandle?: string;
  @Input() theme?: Theme;
  @Output() onSelected = new EventEmitter<ProductOrderLine>();
  product?: Product;


  constructor(private productService: ProductService) {
  }

  async ngOnInit() {
    if (this.orderLine != null && this.storeHandle != null) {
      this.product = await this.productService.getProductById(this.storeHandle, this.orderLine.product.id);
    } else if (this.storeHandle != null) {
      console.error('Missing orderLine');
    } else if (this.orderLine != null) {
      console.error('Missing storehandle');
    }
  }

}
