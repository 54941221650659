<ng-container [ngSwitch]="state">
  <ng-container *ngSwitchCase="loginState.logout">
    <div class="login-container">
      <h5 class="login-title">{{ 'LOGIN.loginTitle' | translate }}</h5>
      <div *ngIf="wrongFormat" class="login__validator">{{ 'LOGIN.validator' | translate }}</div>
      <div *ngIf="numberNotEntered" class="login__validator">{{ 'LOGIN.numberNotEntered' | translate }}</div>
      <div class="login__input__container">
        <mat-form-field class="login__select__container" appearance="fill" >
          <mat-select [(ngModel)]="selectedCountry">
            <mat-option *ngFor="let country of countries" [value]="country">{{country.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="login__input" appearance="fill"  [style.width.%]=50>
          <input matInput
                 placeholder="{{'LOGIN.phoneNumber' | translate }}"
                 type="text" [(ngModel)]="phoneNumber"
                 aria-label="phone-number">
        </mat-form-field>

      </div>
      <button class="login-button" type="submit" (click)="sendSmsCode(phoneNumber!)"
              [ngStyle]="{background: theme?.payForeground, color: theme?.payBackground}">
        {{ 'LOGIN.send' | translate }}
      </button>
      <p class="privacy-policy-message" [innerHTML]="'LOGIN.privacyPolicyMessage' | translate">
      </p>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="loginState.login">
    <div class="login-container">
      <h5 class="login-title">{{ 'LOGIN.sentCode' | translate }}</h5>
      <mat-form-field appearance="standard">
        <input #codeInput
               matInput
               autocomplete="one-time-code"
               inputmode="numeric"
               aria-label="code-number"
               placeholder="{{ 'LOGIN.enterCode' | translate }}"
               [(ngModel)]="smsCode">
      </mat-form-field>
      <button class="login-button" (click)="enterSmsCode(smsCode!)"
              [ngStyle]="{background: theme?.payForeground, color: theme?.payBackground}">
        {{ 'LOGIN.signIn' | translate }}
      </button>
      <h3 *ngIf="wrongCodeMessage" class="login-title-error">{{ 'LOGIN.wrongCode' | translate }}</h3>
    </div>
  </ng-container>
</ng-container>
