import {Component, OnInit} from '@angular/core';
import {ForegroundPaths} from "../../../app-routing.module";
import {ActivatedRoute, Router} from "@angular/router";
import {StoreService} from "../../../domain/store.service";
import {Theme} from "../../../domain/models/store/theme";
import {Store} from "../../../domain/models/store/store";
import {CodeType, ScannerService} from "../../../domain/scanner.service";
import {Subscription} from "rxjs";
import {PaymentService} from "../../../domain/payment.service";
import {CartService} from "../../../domain/cart.service";
import {AgeVerficationResponse} from "../../../transport/models/ageverfication/ageverfication.response";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-age-limit',
  templateUrl: './age-limit.component.html',
  styleUrls: ['./age-limit.component.sass']
})
export class AgeLimitComponent implements OnInit {

  theme?: Theme;
  store?: Store;
  minimized: boolean = false;
  storeScanningSubscription?: Subscription;
  Ageverification?: AgeVerficationResponse

  constructor(private router: Router,
              private storeService: StoreService,
              private cartService: CartService,
              private route: ActivatedRoute,
              private scannerService: ScannerService,
              private paymentService: PaymentService,
              private translateService: TranslateService,
              private toastr: ToastrService
  ) {
  }

  async ngOnInit(): Promise<void> {
    const storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
    this.store = await this.storeService.getStore(storeHandle);
    this.theme = this.store.theme;
  }


  async closeForeground() {
    await this.router.navigate(ForegroundPaths.cart());
  }

  async scanqr() {

    this.minimized = true;
    const primaryRouter = this.route.parent!.firstChild;
    const storeHandle = primaryRouter!.snapshot.paramMap.get('id')!;
    const storeId = (await this.storeService.getStore(storeHandle)).id;
    await this.router.navigate([`/store/${storeHandle}`],
      {relativeTo: primaryRouter});
    this.storeScanningSubscription = this.scannerService
      .onScanResult([CodeType.QR])
      .subscribe(async result => {
        this.storeScanningSubscription?.unsubscribe();
        this.Ageverification = await this.paymentService.ageVerification(storeId, result.barcodeData)
        if (this.Ageverification.isValid) {
          await this.cartService.setAgeCheckPerformed(this.store!.handle);
          await this.router.navigate(ForegroundPaths.cartPayment())
        }else {
          console.error(result);
          const errorMessage = await this.translateService.get('ERROR.GENERIC.description').toPromise();
          this.toastr.warning(errorMessage, undefined, {
            timeOut: 3000,
            easeTime: 100,
            positionClass: 'toast-bottom-center'
          });
          await this.scanqr();
        }
      });
  }

  async stopScanForStore() {
    this.minimized = false;
    this.storeScanningSubscription?.unsubscribe();
  }

}
